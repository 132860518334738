<template>
  <div>
    <v-row>
      <v-col cols="4">
        <app-text-search-field
          :loading="isLoading"
          @input="handleSearchInput($event)"
        />
      </v-col>
      <v-col class="text-right d-md-block d-none" cols="8">
        <v-btn
          class="text-capitalize"
          bottom
          large
          color="primary"
          v-if="$acl.can('representativeBrandCreateEdit')"
          @click="handleStoreRepresentativeByBrand((id = null))"
          >Novo</v-btn
        >
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="representativeBrands.data"
          :items-per-page='-1'
          :options.sync="options"
          @click:row="handleStoreRepresentativeByBrand($event.id)"
          hide-default-footer
        >
          <template v-slot:[`item.status`]="{ item }">
            <status-chip :value="item.status" />
          </template>
          <template v-slot:[`item.doc`]="{ item }">
            <span v-if="item.person_type == 'fisica'">
              {{ $format.cpf(item.cpf) }}
            </span>
            <span v-if="item.person_type == 'juridica'">
              {{ $format.cnpj(item.cnpj) }}
            </span>
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="searchParams.page"
            :length="representativeBrands.last_page"
            @input="select($event)"
            :total-visible="9"
          ></v-pagination>
        </div>
      </v-col>
    </v-row>
    <StoreRepresentativeBrandForm
      @store="select()"
      ref="StoreRepresentativeBrandForm"
    />
  </div>
</template>

<script>
import StoreRepresentativeBrandForm from "@/components/representative-brand/forms/StoreRepresentativeBrandForm.vue";
export default {
  components: { StoreRepresentativeBrandForm },

  data: () => ({
    headers: [
      { text: "Código", align: "center", value: "id" },
      { text: "Name", align: "start", value: "name" },
      { text: "Tipo de pessoa", align: "center", value: "person_type" },
      { text: "CNPJ/CPF", align: "center", value: "doc" },
      { text: "Status", align: "center", value: "status" },
    ],

    options: {},

    isLoading: false,

    searchParams: {
      page: 1,
      orderBy: "name",
      orderDirection: "asc",
      searchText: null,
    },

    representativeBrands: {
      data: [],
    },
  }),

  created() {
    this.select();
  },

  methods: {
    async select(page) {
      this.searchParams.page = page;
      this.isLoading = true;
      await this.$http
        .index("representative-brand/representative-brand", this.searchParams)
        .then((response) => {
          this.representativeBrands = response.representative_brands;
        });
      this.isLoading = false;
    },

    handleSearchInput(text) {
      this.searchParams.searchText = text;
      this.select();
    },

    handleStoreRepresentativeByBrand(id = null) {
      if (this.$acl.can("representativeBrandCreateEdit")) {
        this.$refs.StoreRepresentativeBrandForm.open(id);
      }
    },
  },
};
</script>

<style>
</style>